<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card v-if="requests.length > 0" class="va-table-responsive">
      <va-card-content>
        <table class="va-table va-table--hoverable w-100">
          <thead>
            <tr>
              <th>{{ $t("id") }}</th>
              <th>{{ $t("organizer") }}</th>
              <th>{{ $t("championship_name") }}</th>
              <th>{{ $t("teams_count") }}</th>
              <th>{{ $t("request_date") }}</th>
              <th>{{ $t("reject_date") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="line-height: 1.5"
              v-for="(request, index) in requests"
              :key="request.id"
            >
              <td>{{ index + offset + 1 }}</td>
              <td>{{ request.creator.name }}</td>
              <td>{{ request.name }}</td>
              <td>{{ request.teams_count }}</td>
              <td>{{ new Date(request.created).toLocaleString() }}</td>
              <td>{{ new Date(request.updated).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
        <va-pagination
          v-if="pages > 1"
          color="#0a4695"
          style="direction: ltr"
          class="mt-5"
          v-model="currentPage"
          :visible-pages="3"
          :pages="pages"
        />
      </va-card-content>
    </va-card>
    <div v-else>
      <va-card
        class="row align--center"
        style="height: 35vh"
        stripe
        stripe-color="#1f1f62"
      >
        <va-card-content>
          <h1 class="text-center" style="font-size: 32px">
            {{ $t("no_rejects") }}
            <va-icon class="material-icons" size="32px">
              comments_disabled
            </va-icon>
          </h1>
        </va-card-content>
      </va-card>
    </div>
  </va-inner-loading>
</template>

<script>
import { request, gql } from "graphql-request";

export default {
  name: "rejected-requests",
  data() {
    return {
      requests: [],
      rejectedRequestsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: false,
    };
  },
  async created() {
    // Pagination Logic
    try {
      this.loading = true;
      const REJECTED_REQUESTS_COUNT = gql`
        query {
          ChampsCount(where: { approved: 0 }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        REJECTED_REQUESTS_COUNT
      );
      this.rejectedRequestsCount = counterResponse.ChampsCount.count;
      this.pages = Math.ceil(this.rejectedRequestsCount / this.limit);
      this.getRejectedRequest();
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if ((result.value = true)) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getRejectedRequest() {
      this.loading = true;
      const APPROVED_CHAMPIONSHIPS_REQUESTS = gql`
        query ($limit: Int, $offset: Int) {
          Champs(
            where: { approved: 0 }
            limit: $limit
            offset: $offset
            order: "reverse:updated"
          ) {
            id
            name
            logo
            teams_count
            team_players_count
            has_referees
            champ_type {
              id
              name
              name_ar
            }
            creator {
              name
            }
            created
            updated
          }
        }
      `;
      this.offset = (this.currentPage - 1) * this.limit;
      const response = await request(
        this.$store.state.appUrl,
        APPROVED_CHAMPIONSHIPS_REQUESTS,
        { offset: this.offset, limit: this.limit }
      );
      this.requests = response.Champs;
      this.loading = false;
    },
  },
  watch: {
    currentPage() {
      this.getRejectedRequest();
    },
  },
};
</script>
