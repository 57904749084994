<template>
  <!-- Breadcrumb -->
  <va-card class="row align--center">
    <va-card-content>
      <va-breadcrumbs color="primary">
        <va-breadcrumbs-item :label="$t('championships')" />
        <va-breadcrumbs-item :label="$t('requests')" />
      </va-breadcrumbs>
    </va-card-content>
  </va-card>
  <!-- Tabs -->
  <va-tabs class="mt-4" center hide-slider grow v-model="selectedTab">
    <template #tabs>
      <va-tab name="1" color="primary">
        <va-badge right :text="newRequestsCount" class="mr-4">
          <span class="material-icons">mark_email_unread</span>
          <h4 style="padding: 0 10px">{{ $t("new_requests") }}</h4>
        </va-badge>
      </va-tab>
      <va-tab name="2" color="success">
        <span class="material-icons">domain_verification</span>
        <h4 style="padding: 0 10px">{{ $t("approved_requests") }}</h4>
      </va-tab>
      <va-tab name="3" color="danger">
        <span class="material-icons">cancel_presentation</span>
        <h4 style="padding: 0 10px">{{ $t("rejected_requests") }}</h4>
      </va-tab>
    </template>
    <div v-if="selectedTab == '1'">
      <new-requests />
    </div>
    <div v-else-if="selectedTab == '2'">
      <approved-requests />
    </div>
    <div v-else>
      <rejected-requests />
    </div>
  </va-tabs>
</template>

<script>
import { gql, request } from "graphql-request";
import newRequests from "./components/NewRequests.vue";
import approvedRequests from "./components/ApprovedRequests.vue";
import rejectedRequests from "./components/RejectedRequests.vue";

export default {
  components: { newRequests, approvedRequests, rejectedRequests },
  data() {
    return {
      selectedTab: "1",
      newRequestsCount: 0,
    };
  },
  async mounted() {
    const NEW_REQUESTS_COUNT = gql`
      query {
        ChampsCount(where: { approved: null }) {
          count
        }
      }
    `;
    const counterResponse = await request(
      this.$store.state.appUrl,
      NEW_REQUESTS_COUNT
    );
    this.newRequestsCount = counterResponse.ChampsCount.count;
  },
};
</script>

<style>
.va-tabs__wrapper {
  margin-bottom: 2rem;
}
.va-tabs .va-tabs__container {
  height: 3rem !important;
}
</style>
